
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import ToastService from "../../services/helper/ToastService";
import Multiselect from "vue-multiselect";

import Competition from "../../models/sporteventCalculation/Competition";
import AgeGenderGroup from "../../models/sporteventCalculation/AgeGenderGroup";
import CompetitionService from "../../services/sporteventCalculation/CompetitionService";
import AgeGenderGroupService from "../../services/sporteventCalculation/AgeGenderGroupService";

@Component({
  components: {
    Multiselect,
  },
})
export default class CompetitionComponent extends Vue {
  public competition: Competition = new Competition();
  public allageGenderGroups: AgeGenderGroup[] = [];

  mounted(): void {
    AgeGenderGroupService.getAll().then((items: AgeGenderGroup[]) => {
      this.allageGenderGroups = items;
    });
    if (
      this.$route.params.id !== undefined &&
      this.$route.params.id !== "new"
    ) {
      CompetitionService.get(this.$route.params.id).then(
        (item: Competition) => {
          this.competition = item;
        }
      );
    }
  }

  save(): void {
    CompetitionService.save(this.competition).then((item: Competition) => {
      this.$store.dispatch("localstore_competitions/set");
      ToastService.Success(i18n.tc("labels.saved"), item.name);
      if (item) {
        this.competition = item;
        this.handleBack();
      }
    });
  }

  handleBack(): void {
    this.$router.back();
  }

  getSelectName(ageGenderGroup: AgeGenderGroup): string {
    ageGenderGroup = new AgeGenderGroup(ageGenderGroup);
    return ageGenderGroup.getName();
  }
}

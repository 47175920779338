import Competition from "@/models/sporteventCalculation/Competition";
import BaseService from "../BaseService";
import HttpService from "../HttpService";

class CompetitionService extends BaseService<Competition> {
  public url: string;

  constructor(url = "sportevent-calculation/competition/") {
    super(url, "");
    this.url = url;
  }

  async uploadCsv(formData: FormData) {
    // eslint-disable-next-line
    const response = await HttpService.post<Record<string, any>>(
      "sportevent-calculation/competition-import",
      formData,
      true
    );
    return response;
  }
}

export default new CompetitionService();
